import { alpha } from '@mui/system/colorManipulator';
import {common, green, grey, purple, red, yellow} from '@mui/material/colors';
import type { PaletteOptions } from '@mui/material/styles/createPalette';

import type { ColorPreset, Contrast } from '..';
import { error, info, neutral, success, warning } from 'src/theme/colors';
import { getPrimary } from 'src/theme/utils';

interface Config {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    statoTp: {
      aperto: PaletteColor;
      inCarico: PaletteColor;
      inScarico: PaletteColor;
      terminato: PaletteColor;
    };
    statoCdl: {
      aperto: PaletteColor;
      preventivato: PaletteColor;
      inLavorazione: PaletteColor;
      fatturato: PaletteColor;
      contabilizzato: PaletteColor;
      annullato: PaletteColor;
    };
    statoCronometro: {
      inPausa: PaletteColor;
      inCorso: PaletteColor;
    };
  }

  interface PaletteOptions {
    statoTp?: {
      aperto: PaletteColor;
      inCarico: PaletteColor;
      inScarico: PaletteColor;
      terminato: PaletteColor;
    };
    statoCdl?: {
      aperto: PaletteColor;
      preventivato: PaletteColor;
      inLavorazione: PaletteColor;
      fatturato: PaletteColor;
      contabilizzato: PaletteColor;
      annullato: PaletteColor;
    };
    statoCronometro?: {
      inPausa: PaletteColor;
      inCorso: PaletteColor;
    };
  }
}

export const statusPillColors = {
  green: {
    main: green[900],
    light: green[100],
    dark: green[900],
    contrastText: common.white,
    alpha12: alpha(green[900], 0.12),
  },
  yellow: {
    main: yellow[900],
    light: yellow[100],
    dark: yellow[900],
    contrastText: common.white,
    alpha12: alpha(yellow[900], 0.12),
  },
  grey: {
    main: grey[900],
    light: grey[100],
    dark: grey[900],
    contrastText: common.white,
    alpha12: alpha(grey[900], 0.12),
  },
  red: {
    main: red[900],
    light: red[100],
    dark: red[900],
    contrastText: common.white,
    alpha12: alpha(red[900], 0.12),
  },
  purple: {
    main: purple[900],
    light: purple[100],
    dark: purple[900],
    contrastText: common.white,
    alpha12: alpha(purple[900], 0.12),
  }
}

export const statoTpPalette = {
  aperto: {
    ...statusPillColors.green,
  },
  inCarico: {
    ...statusPillColors.yellow,
  },
  inScarico: {
    ...statusPillColors.grey,
  },
  terminato: {
    ...statusPillColors.red,
  },
}

export const statoCdlPalette = {
  aperto: {
    ...statusPillColors.green,
  },
  preventivato: {
    ...statusPillColors.yellow,
  },
  inLavorazione: {
    ...statusPillColors.yellow,
  },
  fatturato: {
    ...statusPillColors.grey,
  },
  contabilizzato: {
    ...statusPillColors.grey,
  },
  annullato: {
    ...statusPillColors.red,
  },
}

export const statoCronometroPalette = {
  inPausa: {
    ...statusPillColors.grey,
  },
  inCorso: {
    ...statusPillColors.red,
  },
}

export const createPalette = (config: Config): PaletteOptions => {
  const { colorPreset, contrast } = config;

  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12),
    },
    background: {
      default: contrast === 'high' ? neutral[50] : common.white,
      paper: common.white,
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary: getPrimary(colorPreset),
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38),
    },
    warning,
    statoTp: {
      ...statoTpPalette,
    },
    statoCdl: {
      ...statoCdlPalette,
    },
    statoCronometro: {
      ...statoCronometroPalette,
    }
  };
};
