import { useCallback } from 'react';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import {useSignOut} from "react-firebase-hooks/auth";
import {firebaseAuth} from "src/config";
import {useNavigate} from "react-router-dom";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  username: string;
  useremail: string;
}

export const AccountPopover = (props: AccountPopoverProps) => {
  const { anchorEl, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const [signOut] = useSignOut(firebaseAuth);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();
      // Logout
      await signOut();
      navigate(paths.index);
      toast.success('Logout effettuato con successo.');
    } catch (err) {
      console.error(err);
      toast.error('Qualcosa è andato storto!');
    }
  }, [navigate, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{
        sx: { minWidth: 200, marginTop: "5px" },
      }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{props.username}</Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {props.useremail}
        </Typography>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center',
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          Disconnettiti
        </Button>
      </Box>
    </Popover>
  );
};
