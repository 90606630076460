import { alpha } from '@mui/system/colorManipulator';
import type { PaletteOptions } from '@mui/material/styles/createPalette';
import type { ColorPreset, Contrast } from '..';
import { error, info, neutral, success, warning } from 'src/theme/colors';
import { getPrimary } from 'src/theme/utils';
import {common, green, grey, purple, red, yellow} from "@mui/material/colors";

interface Config {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

export const createPalette = (config: Config): PaletteOptions => {
  const { colorPreset, contrast } = config;

  // Adjusting the color shades for dark mode
  const statusPillColorsDark = {
    green: {
      main: green[300], // Lighter shade for dark mode
      light: green[500],
      dark: green[700],
      contrastText: common.black,
      alpha12: alpha(green[300], 0.12),
    },
    yellow: {
      main: yellow[300],
      light: yellow[500],
      dark: yellow[700],
      contrastText: common.black,
      alpha12: alpha(yellow[300], 0.12),
    },
    grey: {
      main: grey[300],
      light: grey[500],
      dark: grey[500],
      contrastText: common.black,
      alpha12: alpha(grey[400], 0.12),
    },
    red: {
      main: red[300],
      light: red[500],
      dark: red[700],
      contrastText: common.black,
      alpha12: alpha(red[300], 0.12),
    },
    purple: {
      main: purple[300],
      light: purple[500],
      dark: purple[700],
      contrastText: common.black,
      alpha12: alpha(purple[300], 0.12),
    }
  };

  // Adjusting statoTpPalette, statoCdlPalette, and statoCronometroPalette for dark mode
  const statoTpPaletteDark = {
    aperto: {
      ...statusPillColorsDark.green,
    },
    inCarico: {
      ...statusPillColorsDark.yellow,
    },
    inScarico: {
      ...statusPillColorsDark.grey,
    },
    terminato: {
      ...statusPillColorsDark.red,
    },
  };

  const statoCdlPaletteDark = {
    aperto: {
      ...statusPillColorsDark.green,
    },
    preventivato: {
      ...statusPillColorsDark.yellow,
    },
    inLavorazione: {
      ...statusPillColorsDark.yellow,
    },
    fatturato: {
      ...statusPillColorsDark.grey,
    },
    contabilizzato: {
      ...statusPillColorsDark.grey,
    },
    annullato: {
      ...statusPillColorsDark.red,
    },
  };

  const statoCronometroPaletteDark = {
    inPausa: {
      ...statusPillColorsDark.grey,
    },
    inCorso: {
      ...statusPillColorsDark.red,
    },
  };

  return {
    // Existing dark mode configurations...
    action: {
      active: neutral[500],
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: alpha(neutral[100], 0.04),
      selected: alpha(neutral[100], 0.12),
    },
    background: {
      default: contrast === 'high' ? '#0B0F19' : '#0E1320',
      paper: neutral[900],
    },
    divider: '#2D3748',
    error,
    info,
    mode: 'dark',
    neutral,
    primary: getPrimary(colorPreset),
    success,
    text: {
      primary: '#EDF2F7',
      secondary: '#A0AEC0',
      disabled: 'rgba(255, 255, 255, 0.48)',
    },
    warning,
    statoTp: {
      ...statoTpPaletteDark,
    },
    statoCdl: {
      ...statoCdlPaletteDark,
    },
    statoCronometro: {
      ...statoCronometroPaletteDark,
    }
    // Other configurations if needed...
  };
};
