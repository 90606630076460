import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "src/layouts/dashboard";

// GENERAL

const NotFound = lazy(() => import('src/pages/404'));

// LOGIN

const Login = lazy(() => import('src/pages/auth/login'));

// CALENDARIO

const Calendario = lazy(() => import('src/pages/calendario'));

// ANAGRAFE

const AnagrafeLista = lazy(() => import('src/pages/anagrafe/anagrafe-index'));
const AnagrafeCreazione = lazy(() => import('src/pages/anagrafe/anagrafe-crea'));
const AnagrafeDettaglio = lazy(() => import('src/pages/anagrafe/anagrafe-dettaglio'));

// VEICOLO

const VeicoloLista = lazy(() => import('src/pages/veicolo/veicolo-index'));
const VeicoloCreazione = lazy(() => import('src/pages/veicolo/veicolo-crea'));
const VeicoloDettaglio = lazy(() => import('src/pages/veicolo/veicolo-dettaglio'));

// PEZZO RICAMBIO

const PezzoRicambioLista = lazy(() => import('src/pages/pezzo-ricambio/pezzo-ricambio-index'));
const PezzoRicambioCreazione = lazy(() => import('src/pages/pezzo-ricambio/pezzo-ricambio-crea'));
const PezzoRicambioDettaglio = lazy(() => import('src/pages/pezzo-ricambio/pezzo-ricambio-dettaglio'));

// SERVIZIO
const ServizioLista = lazy(() => import('src/pages/servizio/servizio-index'));
const ServizioCreazione = lazy(() => import('src/pages/servizio/servizio-crea'));
const ServizioDettaglio = lazy(() => import('src/pages/servizio/servizio-dettaglio'));

// NOTE CLIENTE
const ListaNoteCliente = lazy(() => import('src/pages/note-cliente/note-cliente-index'));
const ListaNoteClienteCreazione = lazy(() => import('src/pages/note-cliente/note-cliente-crea'));
const ListaNoteClienteDettaglio = lazy(() => import('src/pages/note-cliente/note-cliente-dettaglio'));

// AMMINISTRAZIONE
const ListaUtenti = lazy(() => import('src/pages/amministrazione/utenti/utenti-index'));
const UtenteCreazione = lazy(() => import('src/pages/amministrazione/utenti/utenti-crea'));
const DettaglioUtente = lazy(() => import('src/pages/amministrazione/utenti/utenti-dettaglio'));
const AmministrazioneGenerale = lazy(() => import('src/pages/amministrazione/amministrazione-generale'));

// PNEUMATICI
const ListaPneumatici = lazy(() => import('src/pages/pneumatici/pneumatici-index'));
const PneumaticiCreazione = lazy(() => import('src/pages/pneumatici/pneumatici-crea'));
const PneumaticiDettaglio = lazy(() => import('src/pages/pneumatici/pneumatici-dettaglio'));
const PneumaticiMigratedDettaglio = lazy(() => import('src/pages/pneumatici/pneumatici-migrated-dettaglio'));

// CARTELLA DI LAVORO
const ListaCdl = lazy(() => import('src/pages/cdl/cdl-index'));
const CdlCreazione = lazy(() => import('src/pages/cdl/cdl-crea'));
const CdlDettaglio = lazy(() => import('src/pages/cdl/cdl-dettaglio'));
const CdlCronometraggio = lazy(() => import('src/pages/cdl/cdl-cronometraggio'));
const CdlMigratedDettaglio = lazy(() => import('src/pages/cdl/cdl-migrated-dettaglio'));

// CONTI
const ListaConti = lazy(() => import('src/pages/conto/conto-index'));
const ContoCreazione = lazy(() => import('src/pages/conto/conto-crea'));
const ContoDettaglio = lazy(() => import('src/pages/conto/conto-dettaglio'));

export const paths = {
  index: '/',
  notFoundPage: '404',
  login: '/login',
  calendario: '/calendario',
  anagrafe: '/anagrafe',
  anagrafeCreate: '/anagrafe/crea',
  anagrafeGet: '/anagrafe/:id',
  veicolo: '/veicolo',
  veicoloCreate: '/veicolo/crea',
  veicoloGet: '/veicolo/:id',
  pezzoRicambio: '/pezzo-ricambio',
  pezzoRicambioCreate: '/pezzo-ricambio/crea',
  pezzoRicambioGet: '/pezzo-ricambio/:id',
  servizio: '/servizio',
  servizioCreate: '/servizio/crea',
  servizioGet: '/servizio/:id',
  noteCliente: '/note-cliente',
  noteClienteCreate: '/note-cliente/crea',
  noteClienteGet: '/note-cliente/:id',
  utente: '/amministrazione/utenti',
  utenteCreate: '/amministrazione/utenti/crea',
  utenteGet: '/amministrazione/utenti/:id',
  amministrazioneGenerale: '/amministrazione/generale',
  pneumatici: '/pneumatici',
  pneumaticiCreate: '/pneumatici/crea',
  pneumaticiGet: '/pneumatici/:id',
  pneumaticiGetMigrated: '/pneumatici/:id/migrazione',
  cdl: '/cartelle-di-lavoro',
  cdlCreate: '/cartelle-di-lavoro/crea',
  cdlGet: '/cartelle-di-lavoro/:id',
  cronometraggioCdl: '/cartelle-di-lavoro/:id/cronometraggio',
  cdlGetMigrated: '/cartelle-di-lavoro/:id/migrazione',
  conto: '/conto',
  contoCreate: '/conto/crea',
  contoGet: '/conto/:id',
};

export const TopOutlet = () => (
  <Suspense>
    <Routes>
      <Route path="/" element={<Layout />}>
          <Route path={paths.index} element={<Navigate to={paths.calendario} replace />} />
          <Route path={paths.login} element={<Login />} />
          <Route path={paths.calendario} element={<Calendario />} />
          <Route path={paths.anagrafe} element={<AnagrafeLista />} />
          <Route path={paths.anagrafeCreate} element={<AnagrafeCreazione />} />
          <Route path={paths.anagrafeGet} element={<AnagrafeDettaglio />} />
          <Route path={paths.veicolo} element={<VeicoloLista />} />
          <Route path={paths.veicoloCreate} element={<VeicoloCreazione />} />
          <Route path={paths.veicoloGet} element={<VeicoloDettaglio />} />
          <Route path={paths.pezzoRicambio} element={<PezzoRicambioLista />} />
          <Route path={paths.pezzoRicambioCreate} element={<PezzoRicambioCreazione />} />
          <Route path={paths.pezzoRicambioGet} element={<PezzoRicambioDettaglio />} />
          <Route path={paths.servizio} element={<ServizioLista />} />
          <Route path={paths.servizioCreate} element={<ServizioCreazione />} />
          <Route path={paths.servizioGet} element={<ServizioDettaglio />} />
          <Route path={paths.noteCliente} element={<ListaNoteCliente />} />
          <Route path={paths.noteClienteCreate} element={<ListaNoteClienteCreazione />} />
          <Route path={paths.noteClienteGet} element={<ListaNoteClienteDettaglio />} />
          <Route path={paths.utente} element={<ListaUtenti />} />
          <Route path={paths.utenteCreate} element={<UtenteCreazione />} />
          <Route path={paths.utenteGet} element={<DettaglioUtente />} />
          <Route path={paths.amministrazioneGenerale} element={<AmministrazioneGenerale />} />
          <Route path={paths.pneumatici} element={<ListaPneumatici />} />
          <Route path={paths.pneumaticiCreate} element={<PneumaticiCreazione />} />
          <Route path={paths.pneumaticiGet} element={<PneumaticiDettaglio />} />
          <Route path={paths.pneumaticiGetMigrated + '/*'} element={<PneumaticiMigratedDettaglio />} />
          <Route path={paths.cdl} element={<ListaCdl />} />
          <Route path={paths.cdlCreate} element={<CdlCreazione />} />
          <Route path={paths.cdlGet} element={<CdlDettaglio />} />
          <Route path={paths.cronometraggioCdl} element={<CdlCronometraggio />} />
          <Route path={paths.cdlGetMigrated} element={<CdlMigratedDettaglio />} />
          <Route path={paths.notFoundPage} element={<NotFound />} />
          <Route path={paths.conto} element={<ListaConti />} />
          <Route path={paths.contoCreate} element={<ContoCreazione />} />
          <Route path={paths.contoGet} element={<ContoDettaglio />} />
        </Route>
    </Routes>
  </Suspense>
);
