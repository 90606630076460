import {useQuery, type QueryFunction, type UseQueryResult} from "@tanstack/react-query";
import {Id} from "src/types/api/common-types";

export type Sortable = {
  sorting: string,
}

export type Searchable = {
  search: string
}

export type Pageable = {
  pageIndex: number,
  pageSize: number,
}

export type Filterable = {
  filter: string;
}

// In HTTP requests, depending on the endpoint, the query parameters can be all present, partially present, or not present.
export type GetManyQuery = Sortable | Searchable | Pageable | Filterable;
// In components implementing sort, search and paging functions, all 3 must be present.
export type GetManyQueryStrict = (Sortable & Searchable & Pageable) | Filterable;

export type GetByIdPageable = Id & Pageable;

/**
 * Represents a paginated response from the API.
 */
export interface PaginatedResponse<T> {
  result: T[];
  total: number;
}

/**
 * Base hook used to create additional hooks to comunicate with the API regarding data entities.
 */
export const useEntityHook = <Q, R>(entityName: string, queryFn: QueryFunction, queryOptions?: Q, enableRefetch = true): UseQueryResult<R> =>
  useQuery({ queryKey: [entityName, queryOptions], queryFn: queryFn, keepPreviousData: true, enabled: enableRefetch });
