import {itCH} from "date-fns/locale";
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import 'src/global.css';

import { RTL } from 'src/components/rtl';
import { SettingsButton } from 'src/components/settings/settings-button';
import { SettingsDrawer } from 'src/components/settings/settings-drawer';
import { Toaster } from 'src/components/toaster';
import { SettingsConsumer, SettingsProvider } from 'src/contexts/settings';
import { createTheme } from 'src/theme';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Login from "src/pages/auth/login";
import { useAuthState } from "react-firebase-hooks/auth";
import {firebaseAuth} from "src/config";
import {TopOutlet, paths} from "src/routes/paths";
import {useNavigate} from "react-router-dom";
import i18next from "i18next";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
// Import your language translation files
import translation from "zod-i18n-map/locales/it/zod.json";

export const App = () => {
  const navigate = useNavigate();
  const queryClient = new QueryClient()
  const [user, loading] = useAuthState(firebaseAuth);

  useEffect(()=>{
    if (user == null && !loading) {
      navigate(paths.index);
    }
  }, [user, loading])

  // lng and resources key depend on your locale.
  // eslint-disable-next-line import/no-named-as-default-member
  i18next.init({
    lng: "it",
    resources: {
      it: {zod: translation},
    },
  }).then(() => z.setErrorMap(zodI18nMap));

  // const { isAuthenticated } = useAuth();
  // const [checked, setChecked] = useState<boolean>(false);
  //
  // const check = useCallback(() => {
  //   if (!isAuthenticated) {
  //     router.history.replace(paths.login);
  //   } else {
  //     console.log("authenticated")
  //     setChecked(true);
  //   }
  // }, [isAuthenticated]);
  //
  // useEffect(() => check(), [check]);

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itCH} >
        <SettingsProvider>
          <SettingsConsumer>
              {(settings) => {
                const theme = createTheme({
                  colorPreset: settings.colorPreset,
                  contrast: settings.contrast,
                  direction: settings.direction,
                  paletteMode: settings.paletteMode,
                  responsiveFontSizes: settings.responsiveFontSizes,
                });

                return (
                  <ThemeProvider theme={theme}>
                      <Helmet>
                        <meta
                          name="color-scheme"
                          content={settings.paletteMode}
                        />
                        <meta
                          name="theme-color"
                          content={theme.palette.neutral[900]}
                        />
                      </Helmet>
                      <RTL direction={settings.direction}>
                        <CssBaseline/>
                        { user ?
                          <TopOutlet />
                          :
                          loading ? null : <Login />
                        }
                        <SettingsButton onClick={settings.handleDrawerOpen}/>
                        <SettingsDrawer
                          canReset={settings.isCustom}
                          onClose={settings.handleDrawerClose}
                          onReset={settings.handleReset}
                          onUpdate={settings.handleUpdate}
                          open={settings.openDrawer}
                          values={{
                            colorPreset: settings.colorPreset,
                            contrast: settings.contrast,
                            direction: settings.direction,
                            paletteMode: settings.paletteMode,
                            responsiveFontSizes: settings.responsiveFontSizes,
                            stretch: settings.stretch,
                            layout: settings.layout,
                            navColor: settings.navColor,
                          }}
                        />
                        <Toaster/>
                      </RTL>
                    </ThemeProvider>
                );
              }}
          </SettingsConsumer>
        </SettingsProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};
