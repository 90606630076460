import {z} from "zod";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { Seo } from 'src/components/seo';
import {AuthLayout} from "src/layouts/auth/classic-layout";
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormContainer, TextFieldElement} from "react-hook-form-mui";
import {firebaseAuth} from "src/config";
import toast from "react-hot-toast";
import {LoadingButton} from "@mui/lab";

const validationSchema = z.object({
  email: z.string().email('Formato email invalido').max(255).min(1, 'Inserire un indirizzo email'),
  password: z.string().max(255).min(1, 'Password obbligatoria')
});

export type LoginFormType = z.infer<typeof validationSchema>;

const Page = () => {
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(validationSchema),
  })

  const [signInWithEmailAndPassword,,loading] = useSignInWithEmailAndPassword(firebaseAuth);

  const login = async (values: LoginFormType) => {
    try {
      const result = await signInWithEmailAndPassword(values.email, values.password);
      if (result?.user != null) {
        toast.success('Login effettuato con successo.');
      } else {
        toast.error('Credenziali errate.');
      }
    } catch (err) {
      console.error(err);
      toast.error('Qualcosa è andato storto!');
    }
  }

  return (
    <AuthLayout>
      <Seo title="Login" />
      <div>
        <Card elevation={16}>
          <CardHeader
            sx={{ pb: 0 }}
            title="🔐  &nbsp;Accesso Gestionale Autocosmo"
          />
          <CardContent>
            <FormContainer
              formContext={form}
              handleSubmit={form.handleSubmit(login)}
            >
              <Stack spacing={3}>
                <TextFieldElement
                  autoFocus
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextFieldElement
                  label="Password"
                  name="password"
                  type="password"
                />
              </Stack>

              <LoadingButton
                loading={loading}
                size={"large"}
                fullWidth
                sx={{ mt: 4, height: "54px" }}
                type="submit"
                variant="contained"
              >
                Accedi
              </LoadingButton>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 2,
                }}
              >
              </Box>
            </FormContainer>
          </CardContent>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default Page;
