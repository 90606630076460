import type { PaletteColor } from '@mui/material/styles/createPalette';

import type { ColorPreset } from '.';
import {blue, green, indigo, purple, red} from './colors';
import {NavigateFunction} from "react-router/dist/lib/hooks";

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case 'red':
      return red;
    case 'blue':
      return blue;
    case 'green':
      return green;
    case 'indigo':
      return indigo;
    case 'purple':
      return purple;
    default:
      console.error(
        'Invalid color preset, accepted values: "red", "blue", "green", "indigo" or "purple"".'
      );
      return blue;
  }
};

// note: using 'any' because of import problem, should be NavigateFunction of react-router
export const navigateWithCtrlClick = (navigate: any, path: string, e: React.MouseEvent<HTMLTableRowElement>) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
}
