import type { AxiosInstance } from 'axios';
import axios from 'axios';
import {firebaseAuth} from "src/config";

export abstract class HttpClient {
  public readonly currentBaseUrl = import.meta.env.VITE_AUTOCOSMO_BACKEND_URL;
  protected instance!: AxiosInstance;

  protected createInstance(): AxiosInstance {
    this.instance = axios.create({
      baseURL: this.currentBaseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.initializeRequestInterceptor();
    return this.instance;
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(async config => {
      const token = await firebaseAuth.currentUser?.getIdToken();
      config.headers.setAuthorization(`Bearer ${token}`);
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  };
}
