import {Id, TimeMixin} from "./common-types";
import {IUtente} from "./utente-types";
import {IEvento} from "./evento-types";
// eslint-disable-next-line import/no-unresolved
import {PartialDeep} from "type-fest";
import {BaseRepository} from "src/api/client/repository";
import {GetManyQuery} from "src/hooks/entity/use-entity-hook";

export interface INotifica extends TimeMixin, Id {
  titolo: string;
  descrizione?: string;
  letta: boolean;
  data_innesco: Date | null;
  tipo: string;
  edges?: {
    utente: IUtente[];
    evento: IEvento;
  }
}

export type NotificaMutation = PartialDeep<INotifica, {recurseIntoArrays: true}>

class NotificaRepository extends BaseRepository<IEvento, NotificaMutation> {
  constructor() {
    super('notifica');
  }

  getMany(filters: GetManyQuery) {
    return super.getMany(filters);
  }

  get(id: string) {
    return super.get(id);
  }

  create(data: NotificaMutation) {
    return super.create(data);
  }

  update(data: NotificaMutation) {
    return super.update(data);
  }

  delete(id: string) {
    return super.delete(id);
  }

  async getActiveNotifica() {
    const instance = this.createInstance();
    const response = await instance.get(`${this.currentBaseUrl}/${this.path}/active`);
    return this.transform(response);
  }

  async spunta(id: number) {
    const instance = this.createInstance();
    const response = await instance.post(`${this.currentBaseUrl}/${this.path}/spunta/${id}`);
    return this.transform(response);
  }
}

export default NotificaRepository;
