import {Id, TimeMixin} from "./common-types";
import {ICalendario} from "./calendario-types";
// eslint-disable-next-line import/no-unresolved
import {PartialDeep} from "type-fest";
import {BaseRepository} from "src/api/client/repository";
import {IEvento} from "./evento-types";
import {INotifica} from "./notifica-types";

export const ROLE_AMMINISTRATORE = 'amministratore';
export const ROLE_OPERAIO = 'operaio';
export const ROLE_RICEZIONISTA = 'ricezionista';
export const ROLE_VENDITORE = 'venditore';
export const ROLE_CONTABILE = 'contabile';

export const ALLOWED_PREZZO_ACQ_ROLES = [ROLE_CONTABILE, ROLE_AMMINISTRATORE];
export const ALLOWED_ARCHIVAL_ROLES = [ROLE_AMMINISTRATORE, ROLE_CONTABILE, ROLE_RICEZIONISTA];
export const ALLOWED_CONTABILITA_ROLES = [ROLE_AMMINISTRATORE, ROLE_CONTABILE];
export const ALLOWED_DOWNLOAD_FATTURE_ROLES = [ROLE_AMMINISTRATORE, ROLE_CONTABILE, ROLE_RICEZIONISTA, ROLE_VENDITORE];
export const ALLOWED_GENERA_REG_ROLES = [ROLE_CONTABILE, ROLE_AMMINISTRATORE];

export interface IUtente extends TimeMixin, Id {
  uid?: string;
  nome: string;
  ruolo: string;
  email: string;
  disabilitato: boolean;
  edges?: {
    calendario: ICalendario[];
    evento: IEvento[];
    notifica: INotifica[];
  }
}

export interface IUtenteWithPassword extends IUtente {
  password?: string | null;
}

export type UtenteMutation = PartialDeep<IUtenteWithPassword, {recurseIntoArrays: true}>

class UtenteRepository extends BaseRepository<IUtenteWithPassword, UtenteMutation> {
  constructor() {
    super('amministrazione/utente');
  }

  getMany() {
    return super.getMany();
  }

  get(id: string) {
    return super.get(id);
  }

  create(data: UtenteMutation) {
    return super.create(data);
  }

  update(data: UtenteMutation) {
    return super.update(data);
  }

  delete(id: string) {
    return super.delete(id);
  }

  async getByUid(id: string): Promise<IUtente> {
    const instance = this.createInstance();
    const { data } =
      await instance
        .get(`${this.currentBaseUrl}/${this.path}/uid/${id}`);
    return data;
  }

  async getCurrentLoggedInUserProfile(): Promise<IUtente> {
    const instance = this.createInstance();
    const { data } =
      await instance
        .get(`${this.currentBaseUrl}/profile`);
    return data;
  }
}

export default UtenteRepository;
