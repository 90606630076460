import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { Grid } from '@mui/material';


const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundImage: 'url("/assets/login-bg.jpg")',
  backgroundSize: 'cover',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
}));

const OpaqueCover = styled('div')(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',

  height: '100vh',
  width: '100wh',
}));

interface LayoutProps {
  children: ReactNode;
}

export const AuthLayout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            spacing={2}
            sx={{ height: 64 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              display="inline-flex"
              spacing={1}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  height: 64,
                  width: 64,
                  pt: 2,
                  pb: 2
                }}
              >
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <OpaqueCover>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '100vh' }}
        >
          <Container
            maxWidth="sm"
            sx={{
              py: {
                xs: '60px',
                md: '120px',
              },
            }}
          >
            {children}
          </Container>
        </Grid>
      </OpaqueCover>
    </LayoutRoot>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};
