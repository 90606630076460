import {useEffect, useState} from 'react';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';

import { usePopover } from 'src/hooks/use-popover';

import type { Notification } from './notifications';
import { NotificationsPopover } from './notifications-popover';
import {useGetActiveNotifica, useSpuntaNotifica} from "src/hooks/entity/notifica-hooks";
import {INotifica} from "src/types/api/notifica-types";
import toast from "react-hot-toast";

const mapNotificaToNotification = (notifica: INotifica[]): Notification[] => {
  return notifica.map((notifica) => ({
    id: notifica.id.toString(),
    author: "Sistema",
    read: false,
    triggerDate: new Date(notifica.data_innesco ?? 0),
    description: notifica.edges?.evento?.titolo ?? notifica.descrizione ?? "Notifica",
    type: "new_feature",
  }));
};

const useNotifications = () => {
  const fetchNotifica = useGetActiveNotifica();
  const spuntaNotifica = useSpuntaNotifica();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (fetchNotifica.isFetched && !fetchNotifica.error && !fetchNotifica.isRefetching) {
      setNotifications(mapNotificaToNotification(fetchNotifica.data ?? []));
    }
  }, [fetchNotifica.isFetched, fetchNotifica.isRefetching]);

  const unread = notifications.reduce((acc, notification) => acc + (notification.read ? 0 : 1), 0);

  const handleRemoveOne = async (notificationId: string) => {
    try {
      await spuntaNotifica.mutateAsync(parseInt(notificationId));
      await fetchNotifica.refetch();
      toast.success("Notifica spuntata");
    } catch (e) {
      toast.error("Errore nella spunta della notifica");
      console.error("Errore nella spunta della notifica", e);
    }
  };

  return {
    // handleMarkAllAsRead,
    handleRemoveOne,
    notifications,
    unread,
  };
};

export const NotificationsButton = () => {
  const popover = usePopover<HTMLButtonElement>();
  const { handleRemoveOne, notifications, unread } = useNotifications();

  return (
    <>
      <Tooltip title="Notifiche">
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
        >
          <Badge
            color="error"
            badgeContent={unread}
          >
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notifications}
        onClose={popover.handleClose}
        onRemoveOne={handleRemoveOne}
        open={popover.open}
      />
    </>
  );
};
