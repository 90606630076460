import NotificaRepository, {INotifica, NotificaMutation} from "src/types/api/notifica-types";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

const notifica = new NotificaRepository();

export const useGetActiveNotifica = () => {
  return useQuery<INotifica[]>({ queryKey: ['notifica/active'], queryFn: () => notifica.getActiveNotifica(), keepPreviousData: true, refetchInterval: 10000 });
}

export const useUpdateNotifica = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mutationData: NotificaMutation) => {
      return notifica.update(mutationData);
    },
    onSuccess: (data) => {
      // Cache result
      queryClient.setQueryData(['notifica', data.id], data);
    },
  })
}

export const useSpuntaNotifica = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      return notifica.spunta(id);
    }
  })
}
