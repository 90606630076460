import type { AxiosResponse } from 'axios';
import { HttpClient } from 'src/api/client/http-client';
import { GetManyQuery } from "src/hooks/entity/use-entity-hook";

// Since reading an entity might entail a different set of fields than
// writing an entity, we need to define two different type , T for the
// read type, and M for the mutation type.
export interface IBaseRepository<T, M> {
  get(id: any): Promise<T>;
  getMany(filters?: GetManyQuery): Promise<T[]>;
  create(item: M): Promise<T>;
  update(id: any, item: M): Promise<T>;
  delete(id: any): Promise<T>;
}

export abstract class BaseRepository<T, M> extends HttpClient implements IBaseRepository<T, any> {
  protected path: string;

  protected constructor(path: string) {
    super();
    this.path = path;
  }

  public async get(id: string): Promise<T> {
    const instance = this.createInstance();
    return await instance.get(`${this.currentBaseUrl}/${this.path}/${id}`).then(this.transform);
  }

  public async getMany(filters?: GetManyQuery): Promise<T[]> {
    const instance = this.createInstance();
    return await instance.get(`${this.currentBaseUrl}/${this.path}`, {
        params: filters
      }).then(this.transform);
  }

  public async create(item: M): Promise<T> {
    const instance = this.createInstance();
    return await instance
        .post(`${this.currentBaseUrl}/${this.path}`, item)
        .then(this.transform);
  }

  public async update(item: M): Promise<T> {
    const instance = this.createInstance();
    return await instance
      .put(`${this.currentBaseUrl}/${this.path}`, item)
      .then(this.transform);
  }

  public async delete(id: any): Promise<T> {
    const instance = this.createInstance();
    return await instance
      .delete(`${this.currentBaseUrl}/${this.path}/${id}`)
      .then(this.transform);
  }

  transform = (response: AxiosResponse): Promise<any> => {
    return new Promise((resolve) => {
      const result: any = response.data;
      resolve(result);
    });
  };
}
