import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type { ColorPreset } from 'src/theme';
import {blue, green, indigo, purple, red} from 'src/theme/colors';

interface Option {
  label: string;
  value: ColorPreset;
  color: string;
}

interface OptionsColorPresetProps {
  onChange?: (value: ColorPreset) => void;
  value?: ColorPreset;
}

export const OptionsColorPreset = (props: OptionsColorPresetProps) => {
  const { onChange, value } = props;

  const options: Option[] = [
    {
      label: 'Rosso',
      value: 'red',
      color: red.main,
    },
    {
      label: 'Verde',
      value: 'green',
      color: green.main,
    },
    {
      label: 'Blu',
      value: 'blue',
      color: blue.main,
    },
    {
      label: 'Indaco',
      value: 'indigo',
      color: indigo.main,
    },
    {
      label: 'Viola',
      value: 'purple',
      color: purple.main,
    },
  ];

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        Colore primario
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={
              <Box
                sx={{
                  backgroundColor: option.color,
                  borderRadius: '50%',
                  flexShrink: 0,
                  height: 24,
                  width: 24,
                }}
              />
            }
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main',
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

OptionsColorPreset.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['red', 'blue', 'green', 'indigo', 'purple']),
};
