import {Suspense } from 'react';
import PropTypes from 'prop-types';

import { useSettings } from 'src/hooks/use-settings';

import { useSections } from 'src/layouts/dashboard/menu';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';
import {useNprogress} from "src/hooks/use-nprogress";
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  useNprogress();
  const settings = useSettings();
  const sections = useSections();

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
      >
        <Suspense>
          <Outlet />
        </Suspense>
      </HorizontalLayout>
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </VerticalLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
