// eslint-disable-next-line import/no-unresolved
import logo from '/assets/autocosmo_logo.png';
import { Box } from '@mui/material';


// eslint-disable-next-line import/no-unresolved
export const Logo = () => {
  return (
    <Box sx={{backgroundColor: "white", padding: "4px", width: "571px"}}>
      <img src={logo} width={"100%"} alt="Logo"/>
    </Box>
  );
};
