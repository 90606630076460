import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { App } from './app';
import { BrowserRouter } from 'react-router-dom';

// disable Sentry in development
const dsn = import.meta.env.VITE_AUTOCOSMO_SENTRY_DSN;
const mode = import.meta.env.MODE;
const environment = import.meta.env.VITE_AUTOCOSMO_ENV;
const sentryEnabled = import.meta.env.VITE_AUTOCOSMO_SENTRY_ENABLED;

console.log("Running in " + mode + " mode (" + environment + ")");

if (sentryEnabled === "true") {
  Sentry.init({
    debug: true,
    dsn: dsn,
    environment: environment,
    tracePropagationTargets: ["localhost", /^https:\/\/ga.autocosmo\.ch/, /^https:\/\/test.ga.autocosmo\.ch/],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.0,

    integrations: [
      Sentry.captureConsoleIntegration({levels: ['warn', 'error']}),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
